#menu_navbar{
    position: fixed;
    top:0px;
    left:0px;
    z-index:5;
    box-shadow: none;
}
.navbar_btn{
    position: fixed;
    top:10px;
    left:30px;
}
#admin_menu_navbar{
    position:absolute;
    top:57px;
    left:0px;
    max-height:100vh;
    background: #fff;
    box-shadow: 0px 0px 4px 2px lightgray;
    padding:8px;
    min-width:260px;
    text-align: left;
    text-transform: uppercase;
    overflow-y: scroll;
}
#admin_menu_navbar .nav-link{
    margin:2px auto;
    color: #233f78 !important;
    font-size: 18px;
    padding: 10px;
    font-weight: 300;
    background:#fff;
    border-radius:0px 30px 30px 0px;
    transition: background-color 1s, padding-left 1s, color 1s;
}
#admin_menu_navbar .nav-link:hover, #admin_menu_navbar .nav-link.link_active{
    background-color:#233f78;
    padding-left:20px;
    font-weight:400 !important;
    color: #fff !important;
}
.close_btn{
    position: sticky;
    top: 0px;
    right: 0px;
    z-index:3;
    font-size: 30px !important;
}