@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

#main_container{
  font-family: 'Poppins', sans-serif;
  min-height: 100vh !important;
}
.page_header{
  color:#233f78;
  font-weight:400;
}
.small_label{
  color:#233f78;
  font-size: 13px;
  font-weight:600;
}
.xs_label{
  color:#233f78;
  font-size: 12px;
  font-weight:600;
}
.mainLoaderContainer {
  height: 92vh;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 9999;
  background-color: rgba(0,0,0,0.4);
  padding-top: 40vh;
  padding-left: 48%;
  align-items: center;
}
.fixitImageDropView {
  min-height: 500px;
  width: 100%;
  background-color: white;
  /* justify-content: center;
  align-items: center; */
}
.draggableView {
  position: absolute;
  z-index: 9;
  background-color: transparent;
  text-align: center;
  border: 1px solid transparent;
}

.draggableView img {
  cursor: pointer;
  z-index: 10;
}
.answerCard {
  border: 1px solid lightgray;
  padding: 20px;
}
.small_text {
  font-size: 14px;
}

.leftCharacters{
  font-size: 13px;
  color: #f37421;
  font-weight: 500;
}
.standardImagesContainer{
  margin-top: 10px;
  display: inline-block;
  justify-content: space-between;
  border: 1px solid grey;
  padding: 10px 0px;
}
.standardImage{
  max-height: 200px;
  margin: 0px 20px 10px;
}
.dimensionImage{
  height: 350px;
  width: 60%;
  margin: 20px auto;
}
.nameNarrateImage {
  height: 150px;
  width: 60%;
  margin: 20px auto;
}

input{
  color:#233f78;
}
.form-control{
  color:#233f78;
}
.header_text{
  color:#233f78;
  font-size:12vh;
  margin-top: 40vh;
}
.text-orange{
  color:#f37421;
}
.text-blue {
  color:#233f78;
}
.bg-orange{
  background-color:#f37421;
}
.bg-blue {
  background-color:#233f78;
}

.bg-indigo {
  background: #4B0082 !important;
}
.text-indigo {
  color: #4B0082 !important;
}
.viewBtn {
  padding: 6px 12px;
  background-color: #233f78;
  color: white;
  font-size: 12px;
  border-radius: 6px;
  border: none;
}
.loader_container{
  position:fixed;
  z-index:10;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  text-align: center;
  background: rgba(255,255,255,0.8);
}
.loader_image {
  margin-top:30vh;
}

@media (max-width:768px )
{
  .header_text{
    color:#233f78;
    font-size:8vh;
    margin-top: 40vh;
  }
}
